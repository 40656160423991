@import "bootstrap/bootstrap";
/*
* Theme
*/
//@import "themes/flatly-dark/variables"; // Theme
//@import "themes/flatly-dark/bootswatch"; // Theme
//@import "themes/cyborg/variables"; // Theme
//@import "themes/cyborg/bootswatch"; // Theme
//@import "themes/slate/variables"; // Theme
@import "variables";
// Overrides
//@import "themes/slate/bootswatch"; // Theme
@import "/public/fonts/font-awesome/css/font-awesome.min.css";
//@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700|Roboto:300,400,700');
@import "/public/fonts/stencil/styles.css";

//body, label, .checkbox label {
//  font-weight: 400;
//}

body {
  //padding-top: @navbar-height+34;
  //background-repeat:no-repeat;
  //background-position: center top;
  //background-attachment: fixed;
  //
  //-webkit-background-size: cover;
  //-moz-background-size: cover;
  //-o-background-size: cover;
  //background-size: cover;

  //-webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))  //font-size:14px;
}

html {
}

.reveal {
  visibility: hidden;
}

@import "helpers";
@import "type";

@import "navigation";
@import "webshop";

@import (less) "swiper.min.css";
@import "swiper";

@import "isotope";
@import "jasny/jasny-bootstrap";
@import (less) "example4/colorbox.css";
@import "ekko-lightbox";

@import "content";
@import "onepage";

@import "social";
@import "calendar";
@import "forms";
@import "footer";
@import "google";

//@import url("//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700|Open+Sans:300,400,400italic,600,700,800");
//@btn-font-weight: 400;
