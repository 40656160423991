.footer {
  background-color : @gray-base;
  color            : rgba(255, 255, 255, 0.65);
  & h1, h2, h3, h4, h5, h6 {
    font-weight : 700;
    color            : rgba(255, 255, 255, 0.85);
    & a {
      font-weight : 700;
    }
  }
  & h3 {
    color            : rgba(255, 255, 255, 0.85);
    margin-bottom : 0px;
    padding       : 0;
    line-height   : 1;
    font-weight   : 700;
    & a {
      font-weight : 700;
      color            : rgba(255, 255, 255, 0.85);
      &:hover {
        color : rgba(255, 255, 255, 1);
      }
    }
  }
  & h4 {
    color         : inherit;
    margin-bottom : 0px;
    line-height   : 1;
  }
  & p.small {
    color   : rgba(255, 255, 255, 0.25);
    margin  : 0px 0 10px 0;
    padding : 0;
  }
  & a:not('.btn') {
    font-weight : Normal;
    color       : rgba(255, 255, 255, 0.5);
    &:hover {
      color : rgba(255, 255, 255, 1);
    }
  }

  & ul.list-unstyled {
    & li {
      //border-bottom : 1px solid rgba(255, 255, 255, 0.1);
      & a {
        display     : block;
        font-weight : normal;
        padding     : 3px 0px;
        color       : rgba(255, 255, 255, 0.5);
        & small {
          color   : rgba(255, 255, 255, 0.25);
        }
      }
      &:hover:not(.active) a {
        color : rgba(255, 255, 255, 1);
        background-color: transparent;
        & small {
          color   : rgba(255, 255, 255, 0.50);
        }
      }
      &.active a {
        color       : rgba(255, 255, 255, 1);
        font-weight : bold;
        background-color: transparent;
      }
    }
  }
  &.locations {
    background-color : @gray-base;
    & ul {
      margin : 0;
    }
  }
  &.partners {
    background-color : darken(@gray-base, 3%);
    //& ul {
    margin : 0;
    //& li {
    & a {
      display    : block;
      opacity    : 0.5;
      transition : all .2s ease-in;

      &:hover {
        opacity : 1;
      }
    }
    //}
    //}

    /* centered columns styles */
    & .row-centered {
      text-align:center;
    }
    & .col-centered {
      display:inline-block;
      float:none;
      /* reset the text-align */
      text-align:left;
      /* inline-block space fix */
      margin-right:-4px;
    }

  }
  &.credits {
    background-color : darken(@gray-base, 5%);
  }
}