//#map-plug {display:none;}
//
//.google-reviews {
//  display:flex;
//  flex-wrap:wrap;
//  /*display: grid;
//  grid-template-columns: repeat( auto-fit, minmax(320px, 1fr));*/
//}

.review-item {
  border:solid 1px rgba(190,190,190,.35);
  margin:0 auto;
  padding:1em;
  line-height: 1.3;
  background-color: #ffffff;
  //flex: 1 1 20%;
  & .meta {
    display:block;
    //background-color: yellow;
    & .img {
      & img {
        display:inline-block;
        float:left;
        width:40px;
        margin-right:10px;
      }
    }
    & .author {

    }
    height:50px;
  }

  & ul.review-stars {
    & li {
      font-size:@font-size-small;
      color: #c6c6c6;
      //background-color: #00FFFF;
      padding:0px;
      &.active {
        & i {
          color: #e7711b;
        }
      }
      &.inactive {
        & i {
          color: #c6c6c6;
        }
      }
    }
  }
  & .text {
    margin-top:15px;
    line-height: @line-height-base;
  }
}
