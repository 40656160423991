.content {
  & ul, ul.list {
    &.list-unstyled {
      // FAQ menu left
      &.summary {
        margin : 0px 0px 10px;
        & li {
          & a {
            display      : block;
            color        : @gray-light;
            padding-left : 0.8em;
            &.ellipsis {
              width         : 100%;
              overflow      : hidden;
              white-space   : nowrap;
              text-overflow : ellipsis;
            }
            &:hover {
              color : @brand-primary;
            }
            &:before {
              position       : absolute;
              width          : 0.8em;
              margin-left    : -0.8em;
              font-family    : FontAwesome;
              content        : "\f105";
              display        : inline-block;
              color          : @brand-primary;
              //padding-right: 3px;
              vertical-align : middle;
            }
          }
        }
      }
    }
  }

  & ul:not(.pagination):not(.list):not(.navbar-nav):not(.accordion):not(.list-group):not(.summary):not(.accordion):not(.nav-pills):not(.nav-tabs) {
    list-style-type : none;
    padding-left    : 0px;
    margin          : 20px 0 20px 20px; // 0 0 15px;
    & li {
      margin       : 4px 0px;
      padding-left : 10px;
      &:before {
        content     : '\f105';
        font-family : 'FontAwesome';
        color       : @brand-primary;
        float       : left;
        margin      : 0 0 0 -15px;
        font-weight : 600;
      }
    }
  }

}