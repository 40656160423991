
@media (min-width : @screen-xs-min) {
  .grid {
    display:block;
    position:relative;
    .grid-item { width: 100%; }
    //.grid-item--width2 { width: 50%; }
  }
}
@media (min-width : @screen-sm-min) {
  .grid {
    .grid-item { width: 50%; }
  }
}

@media (min-width : @screen-md-min) {
  .grid {
    .grid-item { width: 50%; }
  }
}

@media (min-width : @screen-lg-min) {
  .grid {
    .grid-item { width: 33%; }
  }
}